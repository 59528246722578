import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export const Logo: React.FC = () => {
  const data = useStaticQuery(query)
  return <Img fluid={data.file.childImageSharp.fluid} />
}

const query = graphql`
  query {
    file(relativePath: { eq: "site-logo.png" }) {
      childImageSharp {
        # # Specify the image processing specifications right in the query.
        # # Makes it trivial to update as your page's design changes.
        # fixed(width: 90, height: 90) {
        #   ...GatsbyImageSharpFixed_tracedSVG
        # }
        fluid(maxWidth: 130, maxHeight: 130, fit: CONTAIN) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
