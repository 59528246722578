import { Theme } from "theme-ui"

const fontFamilies = {
  monospace: "Fira Mono",
  sans: "Roboto, system-ui, sans-serif",
  serif: "'Libre Baskerville', Times, serif",
}

export const fonts: Theme["fonts"] = {
  ...fontFamilies,
  body: fontFamilies.sans,
  heading: fontFamilies.sans,
}

export const fontSizes: Theme["fontSizes"] = [
  12,
  14,
  16,
  20,
  24,
  32,
  48,
  64,
  96,
]

export const fontWeights: Theme["fontWeights"] = {
  body: 400,
  heading: 700,
  bold: 700,
}

export const lineHeights: Theme["fontWeights"] = {
  body: 1.5,
  heading: 1.125,
}
