/** @jsx jsx */
import { jsx } from "@emotion/core"
import { Flex, Card, Box, Button } from "theme-ui"
import moment from "moment"
import { Success } from "../Img"
import { Styled } from "theme-ui"
import { navigate } from "gatsby"
import { nyMoment } from "../../util/dates"

const transparentGrayColor = "rgba(82,82,82,0.8)"

interface Props {
  show?: boolean
  curtainColor?: string
  width?: string
  height?: string
  onClose?: Function
}

/** A simple modal container that renders when `show` is true */
export const ModalWrapper: React.FC<Props> = ({
  children,
  show = false,
  curtainColor = transparentGrayColor,
  onClose = () => null,
}) => {
  if (!show) return null

  return (
    <Flex
      sx={{
        justifyContent: "center",
        alignItems: "center",
        bg: ["background", curtainColor],
        zIndex: 100,
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
      onClick={e => {
        e.target === e.currentTarget && onClose()
      }}
    >
      <Card
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          bg: "background",
          position: "relative",
          overflowY: "scroll",
          shadow: [0, 2],
          height: ["100%", "auto"],
          width: ["100%", "auto"],
        }}
      >
        {children}
      </Card>
    </Flex>
  )
}

export interface ModalContentProps {
  onClose: () => void
  modalData: any
}
export type ModalContent = React.ComponentType<ModalContentProps>

export const EventInfo: ModalContent = ({ onClose, modalData }) => {
  const { event } = modalData
  const startMoment = nyMoment(event.start)
  const nowMoment = moment()
  const isToday = startMoment.isSame(nowMoment, "day")

  return (
    <Flex
      sx={{
        flexDirection: "column",
        height: ["100%", "auto"],
        width: ["100%", "400px"],
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Styled.h3 sx={{ mt: 0, textAlign: "center" }}>{event.title}</Styled.h3>
        <Styled.h4>{startMoment.format("ddd, MMM Do YYYY, h:mma")}</Styled.h4>
        {event.extendedProps.description && (
          <Styled.p
          // dangerouslySetInnerHTML={
          //   {
          //     // __html: event.extendedProps.description,
          //   }
          // }
          />
        )}
      </Box>
      {startMoment.isSameOrAfter(nowMoment, "day") && (
        <Button
          variant="outline"
          onClick={() =>
            location.assign(event.extendedProps.actionNetworkBrowserUrl)
          }
        >
          Info & RSVP
        </Button>
      )}
      {isToday && (
        <Button
          variant="outline"
          onClick={() => {
            navigate(event.extendedProps.checkinPath)
            onClose()
          }}
        >
          Check In
        </Button>
      )}
      <Button variant="primary" onClick={onClose}>
        Close
      </Button>
    </Flex>
  )
}

export const SuccessContent: ModalContent = () => {
  return (
    <Flex sx={{ flexDirection: "column", height: "320px", width: "260px" }}>
      <Box sx={{ textAlign: "center", mt: 4, mb: 3 }}>
        <Success />
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Styled.h3 sx={{ mt: 0 }}>Check-in successful!</Styled.h3>
        <Styled.h4>You’re all set.</Styled.h4>
      </Box>
    </Flex>
  )
}

export const ErrorContent: ModalContent = () => {
  return (
    <Flex sx={{ flexDirection: "column", height: "320px", width: "260px" }}>
      <Box sx={{ textAlign: "center" }}>
        <Styled.h3 sx={{ mt: 0 }}>Whoops</Styled.h3>
        <Styled.h4>We’re having trouble handling your registration.</Styled.h4>
      </Box>
    </Flex>
  )
}

export const PrivacyPolicy: ModalContent = ({ onClose }) => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        height: ["640px"],
        width: ["320px"],
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Styled.h3 sx={{ mt: 0, textAlign: "center" }}>
          Privacy Policy
        </Styled.h3>
        <Styled.p>
          We promise not to do anything messed up with your data. We assume you
          are a member of NYC-DSA and therefore, the organization already has
          your email address. We use this data to track attendance across events
          at an aggregate and personal level. This helps us analyze which
          times/locations/topics are most engaging for our membership and also
          to make members aware of upcoming events that they might like. We do
          not share any of this information or information derived from it with
          any organizations outside of NYC-DSA.
        </Styled.p>
        <Styled.p>
          We do not track your location beyond associating your presence with an
          event with a known location. We don’t run any third party Javascript
          on this page.
        </Styled.p>
      </Box>
      <Button onClick={onClose}>Ok</Button>
    </Flex>
  )
}
