import React, { useContext, useEffect, useState } from "react"
import {
  ModalWrapper,
  ModalContent,
  EventInfo,
  ModalContentProps,
  SuccessContent,
  ErrorContent,
  PrivacyPolicy,
} from "./UI/Modal"

type ModalTypeKey = "success" | "networkError" | "privacy" | "eventInfo"

export type ModalType = ModalTypeKey

const contentMap: { [key in ModalTypeKey]: ModalContent } = {
  success: SuccessContent,
  networkError: ErrorContent,
  privacy: PrivacyPolicy,
  eventInfo: EventInfo,
}

interface ModalContextValue {
  modalType?: ModalType | null
  setModalType: (type: ModalType | null) => void
  modalData: ModalContentProps["modalData"]
  setModalData: (data: any) => void
}

export const ModalContext = React.createContext<ModalContextValue>({
  modalType: null,
  setModalType: () => {
    console.warn("default D:")
  },
  modalData: null,
  setModalData: () => {
    console.warn("default D:")
  },
})

export const ModalProvider = ({ children }) => {
  const [modalType, setModalType] = useState<ModalType | null>(null)
  const [modalData, setModalData] = useState<any>(null)

  return (
    <ModalContext.Provider
      value={{
        modalType,
        setModalType,
        modalData,
        setModalData,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export const ModalManager = () => {
  const { modalType, setModalType, setModalData, modalData } = useContext(
    ModalContext
  )
  const [show, setShow] = useState<boolean>(true)

  useEffect(() => {
    if (modalType) {
      setShow(true)
    }
  }, [modalType])

  const onClose = () => {
    setShow(false)
    setModalData(null)
    setModalType(null)
  }

  if (modalType) {
    const Content = contentMap[modalType]
    return (
      modalType && (
        <ModalWrapper show={show} onClose={onClose}>
          <Content modalData={modalData} onClose={onClose} />
        </ModalWrapper>
      )
    )
  } else return null
}
