import { Theme } from "theme-ui"
const allColors = {
  red: "#db1d33",
  black: "#000",
  white: "#fff",
  text: "#202124",
  secondary: "#1E2B39",
  muted: "#f1f3f4",
  gray: {
    "1": "hsl(0,0%,32%)",
    "2": "hsl(0,0%,47%)",
    "3": "hsl(0,0%,58%)",
    "4": "hsl(0,0%,68%)",
    "5": "hsl(0,0%,77%)",
    "6": "hsl(0,0%,85%)",
    "7": "hsl(0,0%,93%)",
  },
}

export const colors: Theme["colors"] = {
  ...allColors,
  background: allColors.white,
  text: allColors.text,
  secondary: allColors.secondary,
  primary: allColors.red,
}
