export const primaryButtonStyle = {
  display: "inline-block",
  background: "none",
  fontFamily: "sans",
  fontSize: 2,

  border: "none",
  py: 3,
  px: 3,
  mx: 1,
  my: 2,
  cursor: "pointer",
  outline: "inherit",
  color: "background",
  bg: "primary",
  boxShadow: 2,

  borderRadius: 3,
  ":disabled": {
    bg: "gray.5",
    color: "muted",
  },
}

export const primaryCardStyle = {
  p: 2,
  m: 1,
  display: "inline-block",
  borderRadius: 2,
}
