import moment from "moment"

/**
 * Preserve time in zone for formatting (switch to `moment-tz` if we have problems with this)
 */
export const nyMoment: (d: string) => moment.Moment = (d: string) => {
  const dateMoment = moment(d)
  return dateMoment.subtract(dateMoment.utcOffset(), "minutes")
}

const nowMoment = moment()
const EARLY_MONTHS_CUTOFF = 12
const LATE_MONTHS_CUTOFF = 12
export const earlyCutoff = nowMoment
  .clone()
  .subtract(EARLY_MONTHS_CUTOFF, "months")
export const lateCutoff = nowMoment.clone().add(LATE_MONTHS_CUTOFF, "months")
