import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export const Success: React.FC = () => {
  const data = useStaticQuery(query)
  return <Img fixed={data.file.childImageSharp.fixed} />
}

const query = graphql`
  query {
    file(relativePath: { eq: "success.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`
