import { Theme } from "theme-ui"
import { colors } from "./colors"
import { fonts, lineHeights, fontWeights, fontSizes } from "./text"
import { styles } from "./htmlStyles"
import { primaryButtonStyle, primaryCardStyle } from "./otherStyles"

export const theme: Theme = {
  breakpoints: ["40em", "52em", "64em"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,

  shadows: ["none", "inset 0 0 0 2px", "0 0 16px rgba(0, 0, 0, .25)"],
  radii: [0, 2, 3, 5, 10],
  /* HTML styles when importing { Styled } from 'theme-ui' */
  styles,
  /** Custom variants defined by theme-ui
   * https://theme-ui.com/components/variants
   * Usage <Button variant="outline" />
   */
  buttons: {
    primary: primaryButtonStyle,
    outline: {
      ...primaryButtonStyle,
      color: "primary",
      bg: "background",
      boxShadow: 1,
    },
  },
  cards: {
    primary: primaryCardStyle,
    shadow: {
      ...primaryCardStyle,
      boxShadow: 2,
    },
  },
  text: {
    heading: {
      lineHeight: "heading",
      fontWeight: "heading",
      fontFamily: "heading",
      fontSize: 5,
    },
    /* Previous default <Text /> style, theme-ui does not have a default variant for <Text /> */
    //   Text: {
    //     lineHeight: "body",
    //     whiteSpace: "pre-wrap",
    //     fontFamily: "body",
    //   },
  },
} as Theme
